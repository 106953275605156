@media (min-width:0px) and (max-width:766px){
  /* .gallery-container{
  display: grid; 
  grid-template-columns: repeat(auto-fill,minmax(90%, 1fr)) !important;
  flex-direction: row;
  margin-left: .3rem !important;
  } */
   /* .gallery-Image{
    width: 330;
  }  */
  /* .gallery-paragraph{
    align-items: center !important;
    display: inline !important;
  } */
  /* .service-container{
    flex-direction: column !important;
    margin-left: 1 !important;
    margin-right: 1rem !important;
    margin-top: 1rem !important; 
    text-align: center;
    justify-content:space-between;
    } */
     /* #divider{
    width: 400px;
    } */
  
  /* .important-text {
    margin-left: 1.5rem !important;
    font-weight:300;
  } */
  /* .location-container {
    flex-direction: column;
    align-items: center;
  } */
  /* .map-address {
    margin-top: .5rem !important;
    margin-left: .5rem !important;
    margin-bottom: 1rem !important;
  } */
  /* .map-iframe {
    margin-top: 0.5rem !important;
    margin-bottom: 1rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    width: 330;
    height: 250;
  } */
  /* #servicesBar{
    font-size: 2rem !important;
    width: 415 !important;
  } */
  #footer{
    padding: 20 !important;
    flex-direction: column;
  }
  .privacyItem{
    flex-direction: column;
  }
}
.main {
  font-family: 'Lato', 'sans-serif';
}
html {
  scroll-behavior: smooth;
}

/* .landing-page-image {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
} */

/* .landing-page-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
} */


/* .landing-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 100rem;
} */

#landingImagetitle{
  margin-bottom: 1rem;
  color: #3d85c6;
}

#landingImagetext1{
  font-size: 3rem;
  margin-bottom: 3rem;
  color: #990000;
}

#landingImagetext2{
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.5rem;
  text-align: center;
}

.landing-page-header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
}

.company-name {
  font-size: 2rem;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 1rem;
  transition: background-color 0.3s ease-in-out;
}

.company-name:hover {
  color: #3d85c6;
  cursor: pointer;
}

.arrow-down-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
}

.arrow-down-link {
  font-size: 2rem;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 1rem;
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}

.arrow-down-link:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.fa-chevron-down {
  display: block;
  margin-top: 0.2rem;
}
/* .landing-page-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(54, 54, 54);
} */
/* .contact {
  color: white;
  background-color: rgb(54, 54, 54);
  text-align: center;
  display: inline-block;
  padding: 1.5rem 0rem;
} */
/* .contactText{
  font-size: 0.9rem;
} */

/* #divider {
  height: 0.5px;
  width: 80%;
  margin-left: 10%;
  background-color: grey;
} */

/* .map-iframe {
  border: 0;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  margin-left: 9rem;
} */
/* .location-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: 'wrap';
} */
/* .map-address {
  flex: 1;
  color: black;
  margin-left: 3rem;
  margin-top: 3.5rem;
  font-family: "Lato";
  margin-left: -55rem;
} */
/* .map-address p{
  font-size: 1rem;
} */
/* .service-container {
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-left: 11rem;
} */
/* .services-list {
  color: black;
} */
/* .map-wrapper {
  width: 95%;
  flex: 1;
  padding-left: 1px;
} */
/* .important-text {
  color: black;
  margin-left: -35rem;
} */
/* .important-text p{
  font-size: 1rem;
} */
/* .service-container ul {
  list-style-type: square;
}
.service-container ul li {
  margin: 0.5rem;
} */
/* .gallery-container { 
  display:grid; 
  grid-template-columns: repeat(auto-fill,minmax(33.333%, 1fr));
  align-items: center;
  margin-left: 9rem;
  margin-right: 9rem;
  margin-top: 5rem;
  justify-content: center;
  margin-bottom: 5rem;
} */
/* .gallery-Image {
  margin-top: 2rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
} */
/* .gallery-paragraph {
  padding: .5rem;
  display: inline;
  color: black;
  text-align: center;
  font-family: "Lato";
  font-weight: 500;
}
.gallery-paragraph p{
  font-size: 1rem;
} */
/* #servicesBar{
  background-color: rgb(54, 54, 54);
  width: 100%;
  height: 6rem;
  color: white;
  text-align: center;
  font-size: 3rem;
  font-family: "Lato", 'sans-serif';
  padding-top: 2rem;
 } */
 #privacy, #terms {
  padding: 20px 50px 50px 50px;
}

#imprint {
  padding: 20px 50px 200px 50px;
}

.privacyItem{
  padding-top:1rem;
  margin-right: 50%;
  width: 100px;
  color: #608dfd;
}

.privacyItem:hover {
  text-decoration: underline;
  cursor: pointer;
}

#legalSection{
  margin-left: 5%;
  min-width: max-content;
  display: flex;
}

.back-to-home {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.back-to-home-a {
  font-size: 2rem;
  color: #333;
  text-decoration: underline;
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 1rem;
  transition: background-color 0.3s ease-in-out;
}

